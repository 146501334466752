import React, { useRef } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineShareAlt,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import { CiClock1 } from "react-icons/ci";
import { BiMessageDetail } from "react-icons/bi";
import Slider from "react-slick";

const OurStores = () => {
  const storie1 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/11.png";
  const storie2 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/RegistrationofSuppliers.png";
const storie51 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/SaleOfProperties1.png";
const storie5551 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Tendorforconsultancy1.png";
const storie21 = "https://techrowth.s3.eu-north-1.amazonaws.com/q3twge.jpg";

const storie11 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/IZB-TENDER-BRANCH-FITOUT-PRESS-AD-25X4-RVS-V2.png";
const storie4111 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/file123.png";
  
const storie4 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/13.png";
const storie5 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/14.png";
const arroww123 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/Propertiesforsale1.png";
const arroww1234 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/1+(1).png";
const aarating = "https://techrowth.s3.eu-north-1.amazonaws.com/AA-Credit.png";
const arrowwreport = "https://techrowth.s3.eu-north-1.amazonaws.com/report.png";
const arrowwreport1 = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/Thumbnailnew_Image.png";
const arrowreport2 = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/Blog_Image.png";
const arroww1 = "https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth-ZM/IZB+Annual+Report.pdf";
const arroww12345 = "https://techrowth.s3.eu-north-1.amazonaws.com/images/New/properties-for-sale-june.png";
const rfp = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/RFP_Thumbnail.png";
const solar = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/solar-hybrid.png";
const arroww = "https://techrowth.s3.eu-north-1s.amazonaws.com/images/chevron_right.svg";
const payment = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/payment-card.png";
const addendum = "https://techrowth.s3.eu-north-1.amazonaws.com/izb/addendum-Thumbnail_2.png";

  const sliderRef = useRef(null);
  console.log(sliderRef.current);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="heading py-3">
              <h2 className="text-center">Our Stories</h2>
              <p className="text-center">All News And Updates</p>
            </div>
            <div
              className="py-4"
              style={{ float: "right"}}
            >
              <Link
                to="/Stories"
                className="text-white rounded px-3 py-1"
                style={{ backgroundColor: "#9E1B1E" }}
              >
                See More <AiOutlineArrowRight size={21} className="me-1 pb-1" />
              </Link>
            </div>
          </div>



          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/REQUEST+FOR+PROPOSAL+FOR+THE+IMPLEMENTATION+OF+PAYMENT+CARD+INDUSTRIAL+DATA+SECURITY+STANDARD+AT+IN.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={addendum} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/ADDENDUM+1-+RFP.pdf">
    ADDENDUM 1 FOR SOLAR CONSULTANCY TENDER
      
    </a><br/><br/><br/><br/>
    <span style={{ fontSize: "15px", color: "gray" }}>Sep 04, 2024</span>
  </h3>
  <p style={{ marginBottom: "0rem" }}>
  Consultants are advised to take note of the responses to queries raised for the aforementioned tender for consultancy services to undertake load analysis, design, supervision and commissioning of solar system for IZB...
  </p><br/>
  <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/ADDENDUM+1-+RFP.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>

            </div>
          </div>







          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/RFP.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={rfp} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/RFP.pdf">
    CANCELLED: REQUEST FOR PROPOSAL FOR THE IMPLEMENTATION OF THE PAYMENT CARD INDUSTRY DATA SECURITY STANDARD
      
    </a><br/>
    <span style={{ fontSize: "15px", color: "gray" }}>Aug 31, 2024</span>
  </h3>
  <p style={{ marginBottom: "0rem" }}>
  Cancelled: Indo Zambia Bank invites sealed proposals from eligible prospective Consultants for the implementation of the payment Card Industry Data Security Standard (PCI DSS)...
  </p><br/><br/>
  <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/RFP.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>

            </div>
          </div>








          <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/REQUEST+FOR+PROPOSAL+FOR+THE+IMPLEMENTATION+OF+PAYMENT+CARD+INDUSTRIAL+DATA+SECURITY+STANDARD+AT+IN.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={payment} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/REQUEST+FOR+PROPOSAL+FOR+THE+IMPLEMENTATION+OF+PAYMENT+CARD+INDUSTRIAL+DATA+SECURITY+STANDARD+AT+IN.pdf">
    Request For Proposal for The Implementation Of payment Card Industrial Data Security Standard
      
    </a><br/><br/>
    <span style={{ fontSize: "15px", color: "gray" }}>Aug 16, 2024</span>
  </h3>
  <p style={{ marginBottom: "0rem" }}>
  Indo Zambia Bank invites sealed proposals from eligible prospective Consultants for the implementation of the payment Card Industry Data Security Standard (PCI DSS)...
  </p><br/><br/>
  <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/REQUEST+FOR+PROPOSAL+FOR+THE+IMPLEMENTATION+OF+PAYMENT+CARD+INDUSTRIAL+DATA+SECURITY+STANDARD+AT+IN.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>

            </div>
          </div>



          {/* <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/REQUEST+FOR+PROPOSAL-+CONSULTANCY+FOR+THE+PROVISION+OF+DESIGN+AND+SUPERVISION+OF+A+SOLAR+HYBRID+INV.pdf">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={solar} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/REQUEST+FOR+PROPOSAL-+CONSULTANCY+FOR+THE+PROVISION+OF+DESIGN+AND+SUPERVISION+OF+A+SOLAR+HYBRID+INV.pdf">
    Request For Proposal- Consultancy For The Provision Of Design And Supervision Of A Solar Hybrid Inverter And Battery System Across Izb Branch Network
      
    </a><br/><br/>
    <span style={{ fontSize: "15px", color: "gray" }}>Aug 16, 2024</span>
  </h3>
  <p style={{ marginBottom: "0rem" }}>
  Indo Zambia Bank intends to install Solar Hybrid Inverters with Battery storage across its Branch and Automatic Teller Machines (ATMs) network Countrywide...
  </p><br/>
  <a href="https://techrowth.s3.eu-north-1.amazonaws.com/izb/REQUEST+FOR+PROPOSAL-+CONSULTANCY+FOR+THE+PROVISION+OF+DESIGN+AND+SUPERVISION+OF+A+SOLAR+HYBRID+INV.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>

            </div>
          </div> */}






          {/* <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/TenderInvitations">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={arrowreport2} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="/TenderInvitations">
    Tender Invitation For Recruitment & Management Of Agents, Services Of IZB Wallet Banking
      <br/><br/><br/>
     <br/>
  
    </a>
    <span style={{ fontSize: "15px", color: "gray" }}>July 24, 2024</span>
  </h3>
  <p style={{ marginBottom: "0rem" }}>
  Indo Zambia Bank Limited has set aside funds to outsource the management of agents and targeted acquisition of customers. The Bank now invites sealed bids from eligible...
  </p>
  <br/>
  <a href="/TenderInvitations">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>

            </div>
          </div> */}











          {/* <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/Invitationn">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={arrowwreport1} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="/Invitationn">
    Tender For Solar Hybrid Inverter And Battery System Across IZB Branch Network
      <br/><br/>
    </a><br/>
    <span style={{ fontSize: "15px", color: "gray" }}>July 15, 2024</span>
  </h3>
  <p style={{ marginBottom: "0rem" }}>
  Indo Zambia Bank has set aside funds for the procurement of Solar Hybrid Inverter and Battery System across its Branch network and would like to apply part of the funds...
  </p>
  <br/>
  <a href="/Invitationn">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0" }}/>
</div>

            </div>
          </div> */}








          {/* <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/AnnualReport">
              <div className="img-holder">
                <div className="inner">
                  <img className="imgresize" src={arrowwreport} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
             



<div className="text-holder">
  <h3 className="blog-title mt-4">
    <a href="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth-ZM/IZB+Annual+Report.pdf">
      Indo Zambia Bank Annual Report - Dec 2023
      <br/><br/><br/>
    </a><br/>
    <span style={{ fontSize: "15px", color: "gray" }}>June 01, 2024</span>
  </h3>
  <p style={{ marginBottom: "0rem" }}>
  Dear Esteemed Stakeholders, it is with great pleasure that we present to you Indo Zambia Bank’s Annual Report for the year ending December 2023...
  </p>
  <br/>
  <a href="https://techrowth.s3.eu-north-1.amazonaws.com/Techrowth-ZM/IZB+Annual+Report.pdf">
    <h5 style={{ fontWeight: "500", color: "#9E1B1E", textAlign: "right" }}>
      Read More <img src={arroww1} alt="" width="8%"/>
    </h5>
  </a>
  <hr style={{ color: "#9E1B1E", margin: "0rem 0"}}/>
</div>

            </div>
          </div> */}





         




          
          {/* <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/Propertiesforsalejune">
              <div className="img-holder">
                <div className="inner">
                  <img src={arroww12345} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Properties For Sale<br/><br/><br/><br/></a>
                  <span style={{fontSize: "15px", color: "gray"}}>June 01, 2024</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                INDO ZAMBIA BANK LIMITED as mortgagees in possession is inviting bids from interested parties for purchase of the following properties on...
                </p><br/>
                <Link
                to="/Propertiesforsalejune">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div> */}

         


          {/* <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
                <a href="/Workerscopensation">
              <div className="img-holder">
                <div className="inner">
                  <img src={storie21} alt="" />
           
                    <div className="overlay-icon"></div>
              
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
             
                  <a>IZB - Workers Compensation Fund Control Board Zambia E-Workers Smart Pay Launch
</a><br/>

                  <span style={{fontSize: "15px", color: "gray"}}>October 20, 2023</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                Indo Zambia Bank is proud to announce the launch of the provision of the E-Workers on our INDO Internet Banking platform .This INDO Net Banking platform ...
                </p><br/><br/>
                <Link
                to="/Workerscopensation">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div> */}

          {/* <div className="col-xl-4 col-lg-4">
            <div
              className="single-blog-style1 wow fadeInUp"
              data-wow-delay="00ms"
              data-wow-duration="1500ms"
            >
            <a href="/Propertiesforsale2024">
              <div className="img-holder">
                <div className="inner">
                  <img src={arroww1234} alt="" />
            
                    <div className="overlay-icon"></div>
                
                </div>
              </div>
              </a>
              <div className="text-holder">
                <h3 className="blog-title mt-4">
                  <a>Properties For Sale<br/><br/><br/></a>
                  <span style={{fontSize: "15px", color: "gray"}}>April 02, 2024</span>
                </h3>
                <p className="" style={{marginBottom:"0rem"}}>
                INDO ZAMBIA BANK LIMITED as mortgagees in possession is inviting bids from interested parties for purchase of the following properties ...
                </p><br/>
                <Link
                to="/Propertiesforsale2024">
             <h5 style={{fontWeight:"500", color:"#9E1B1E", textAlign:"right"}}>Read More <img src={arroww} alt="" width="8%"/></h5></Link>
             <hr style={{ color:"#9E1B1E", margin:"0rem 0"}}/>
            
              </div>
            </div>
          </div>
      */}

       
        
        
  
     
         
     

         
          
          {/* </Slider> */}
          {/* <div className="d-flex justify-content-between mb-4">
            <button type="button" className="btn btn-light border">
 
              <AiOutlineArrowLeft
                color="#B70A00"
                size={21}
                className="me-1 pb-1"
              />
              PREV
            </button>
            <button type="button" className="btn btn-light border">
              NEXT

              <AiOutlineArrowRight
                color="#B70A00"
                size={21}
                className="me-1 pb-1"
              />
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default OurStores;
